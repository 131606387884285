<template>
    <div id='about-us'>
        <v-snackbar v-model="data.snack.show" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ data.snack.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="data.snack.show = false"> 关闭</v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-spacer></v-spacer>
                <div class='d-flex align-center flex-wrap'>
                    <v-btn class='mb-4 me-3' color='primary' @click="updateAgreement">
                        <v-icon>{{ icons.mdiPlus }}</v-icon>
                        <span>保存</span>
                    </v-btn>
                </div>
            </v-card-text>
            <vue-tinymce v-model="data.content" :setting="tinymceSetting"/>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import {mdiPlaylistEdit, mdiDeleteOff,} from '@mdi/js'
import store from '@/store'
import {onMounted, onUnmounted} from '@vue/composition-api'
import contentStoreModule from '../contentStoreModule'
import contentAgreement from './contentAgreement'

export default {
    components: {}, setup() {
        const CONTENT_CONTENT_STORE_MODULE_NAME = 'content-agreement'
        // Register module
        if (!store.hasModule(CONTENT_CONTENT_STORE_MODULE_NAME)) store.registerModule(CONTENT_CONTENT_STORE_MODULE_NAME, contentStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CONTENT_CONTENT_STORE_MODULE_NAME)) store.unregisterModule(CONTENT_CONTENT_STORE_MODULE_NAME)
        })
        const {data, tinymceSetting, fetchAgreement, updateAgreement} = contentAgreement()
        fetchAgreement()
        return {
            data,
            tinymceSetting,
            fetchAgreement,
            updateAgreement,
            // icons
            icons: {mdiPlaylistEdit, mdiDeleteOff},
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
