import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        // 根据类型获取内容管理
        fetchContentInfo(ctx, type) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/content/info?type=${type}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        // 更新内容管理
        updateContentInfo(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/content/info`, { type: data.type, content: data.content }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

    },
}
